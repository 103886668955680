<template>
	<div class="row">
		<div class="col-12 flex flex-col gap-4">
			<button @click="openAccountModal" class="text-white w-fit border-1 border-black bg-black px-4 rounded py-2 ml-auto">
				Add Account
			</button>
			<div class="card">
				<b-table
					striped
					hover
					selectable
					responsive
					show-empty
					:items="tableData"
					:fields="fields"
					:current-page="currentPage"
					:busy="loading"
					>
					<template #table-busy>
						<div class=" d-flex flex-column justify-content-center align-items-center">
							<div class="spinner-border" role="status"></div>
							<p class="text-center mt-2"><strong>Loading...</strong></p>
						</div>
					</template>

					<template #empty>
						<p class="text-center text-secondary">No records available</p>
					</template>
					<template #cell(serialNumber)="data">
						<p>
							{{ data.index + 1 }}
						</p>
					</template>
					<template #cell(isDefault)="data">
						<p class="font-bold" :class="data.item.isDefault ? 'text-green-700' : 'text-gray-400'">
							{{ data.item.isDefault ? 'Assigned' : 'Not Assigned' }}
						</p>
					</template>
					<template #cell(actions)="data">
						<div class="flex items-center justify-end gap-4">
							<button v-if="!data.item.isDefault" class="text-white border-1 border-black bg-black px-3 rounded py-2" @click="assignAccount(data.item)">
								Assign
							</button>
							<button class="text-red-500 border-1 border-red-500 bg-transparent px-3 rounded py-2 " @click="removeAccount(data.item)">
								Remove
							</button>
						</div>
					</template>
					
				</b-table>

				<div class="card-footer" v-if="totalRecords">
					<div class="row align-items-center">
						<div class="col">
							<span class="text-dark font-weight-bold">{{
								tablePageText
							}}</span>
						</div>
						<div class="col-auto">
							<b-pagination
								v-model="currentPage"
								:total-rows="totalRecords"
								:per-page="pageSize"
								></b-pagination>
						</div>
					</div>
				</div>
			</div>
		</div>
		<b-modal ref="accountModal" @close="closeAccountModal" size="sm" :hide-footer="true" no-close-on-backdrop no-close-on-esc>
			<template #modal-title> Add Bank Account </template>
			<div class="py-2">
				<form class="w-full flex flex-col gap-3" @submit.prevent="AddNewAccount">
					<div class="flex flex-col gap-1">
						<p class="text-black fontn-medium m-0">Bank Name</p>
						<select required v-model="bankObj" class="border rounded p-2 outline-none">
							<option value="">Select Bank</option>
							<option v-for="(bank, index) in allBanks" :key="index" :value="bank">
								{{ bank.name }}
							</option>
						</select>
					</div>
					<div class="flex flex-col gap-1 relative">
						<p class="text-black fontn-medium m-0">Account Number</p>
						<input required v-model="accNumber" type="number" class="border rounded p-2 outline-none">
						<p v-if="accNumber.length && accNumber.length < 10" class="m-0 text-xs text-red-600 font-medium">Account Number is not complete</p>
						<div v-if="fetchingAccountName" class="absolute bottom-[-1px] right-2">
							<div class="spinner-border" role="status"></div>
						</div>
					</div>
					<div class="flex flex-col gap-1">
						<p class="text-black fontn-medium m-0">Account Name</p>
						<input required v-model="accName" readonly type="text" class="border rounded bg-gray-100 p-2 outline-none">
					</div>
					<button :disabled="!formValid" type="submit" :class="formValid ? 'bg-black' : 'border-0 bg-gray-400 cursor-not-allowed'"  class="text-white bg-black rounded w-full flex items-center justify-center h-[40px] mt-[20px]">
						<span v-if="!processing">Add Account</span>
						<div v-else class="spinner-border" role="status"></div>
					</button>
				</form>
			</div>
		</b-modal>
	</div>
</template>

<script>
import tableView from '@/mixins/tableview'
import UserAvatar from '@/components/core/UserAvatar'
import DeletePromptModal from '@/components/modals/DeletePromptModal'
import { extractErrorMessage } from '@/utils/helpers'
import { ExportToCsv } from 'export-to-csv'
import DownloadButton from '@/components/core/DownloadButton.vue'
import banks from 'ng-banks'

export default {
  name: 'PartnerDrivers',
  components: { UserAvatar, DeletePromptModal, DownloadButton },
  mixins: [tableView],
  props: {
    partnerId: {
      type: [Number, String],
      required: true
    }
  },
  data () {
    return {
      pageSize: 10,
      currentPage: 1,
      totalRecords: 0,
      loading: false,
      errorLoading: false,
      currentDriver: null,
      tableData: [],
      fields: [
        {
          key: 'serialNumber',
          label: 'S/N'
        },
        {
          key: 'accountNumber',
          label: 'Account Number'
        },
        {
          key: 'bankName',
          label: 'Bank Name'
        },
        {
          key: 'accountName',
          label: 'Account Name'
        },
        {
          key: 'isDefault',
          label: 'Assigned Account'
        },
        {
          key: 'actions',
          label: 'Actions'
        }
      ],
      accountSid: this.$route.params.accountSid,
	  allBanks: [],
	  bankObj: {},
	  accNumber: '',
	  accName: '',
	  fetchingAccountName: false,
	  processing: false
    }
  },
  watch: {
    currentPage (value) {
      if (value) {
        this.fetchPartnerAccounts(false)
      }
    },
	accNumber (value) {
		this.accName = ''
		if (value) {
			if(value.length >= 10 && this.bankObj.name) this.validateAccountNumber()
		}
    },
	bankObj (value) {
		this.accName = ''
		if (value) {
			if(this.accNumber.length >= 10 && this.bankObj.name) this.validateAccountNumber()
		}
	}
  },
  created () {
	this.showBanks()
    this.fetchPartnerAccounts()
  },
  computed: {
    formValid() {
		return (this.bankObj && this.accName && this.accNumber) ? true : false
	}
  },
  methods: {
	async AddNewAccount() {
      this.processing = true
      const payload = {
        accountNumber: this.accNumber,
        accountName: this.accName,
        bankCode: this.bankObj?.code,
        bankName: this.bankObj?.name,
        partnerId: this.$route?.params?.accountSid
      }
      try {
        await this.$axios.post('/cost-revenue/v1/settlement-accounts', {
          ...payload
        })
        this.$swal({
			icon: 'success',
			title: 'Success',
			text: 'Account added successfully',
			showCloseButton: true
		})
		this.fetchPartnerAccounts()
		this.closeAccountModal()
      } catch (error) {
        const errorMessage = extractErrorMessage(
          error,
          null,
          'Oops! An error occurred, please try again.'
        )
        this.$swal({
			icon: 'error',
			title: 'Error',
			text: errorMessage,
			showCloseButton: true
		})
      } finally {
        this.processing = false
      }
    },
	validateAccountNumber() {
      this.fetchingAccountName = true
      this.$axios
        .get(
          `/v1/banks/resolve-accounts?bank_code=${this.bankObj.code}&account_number=${this.accNumber}`
        )
        .then((res) => {
			this.accName = res?.data?.account_name
        })
        .catch((error) => {
			this.$swal({
				icon: 'error',
				title: 'Error',
				text: error.response.data.message || 'No name found',
				showCloseButton: true
			})
        })
		.finally(() => {
			this.fetchingAccountName = false
        })
    },
	showBanks() {
      const ngBanks = banks.getBanks()
      this.allBanks = ngBanks || []
    },
    fetchPartnerAccounts (showLoader = true) {
      if (showLoader) this.loading = true
      this.axios
		.get(`/cost-revenue/v1/settlement-accounts?partnerId=${this.$route?.params?.accountSid}`)
        .then((res) => {
          this.tableData = res.data
        //   this.totalRecords = res.data.metadata?.total
        })
        .finally(() => {
          this.loading = false
        })
    },
	async assignAccount (obj) {
		await this.$swal({
			icon: 'question',
			title: 'Please Confirm',
			text: 'Are you sure you want to assign this account?',
			showConfirmButton: true,
			showCancelButton: true,
			preConfirm: () => {
				// https://api.test.shuttlers.africa/cost-revenue/v1/settlement-accounts/e84966c8-a73b-4dfb-b016-1d212cf38964
			  return this.axios
				.patch(
				  `/cost-revenue/v1/settlement-accounts/${obj.id}`,
				  {
					accountName : obj.accountName,
					accountNumber: obj.accountNumber,
					bankName: obj.bankName,
					isDefault: true
				  }
				)
				.catch((error) => {
				  const msg =
					error.response && error.response.data
					  ? error.response.data.message
					  : 'An error occured, please try again.'
	
				  this.$swal().showValidationMessage(msg)
				})
				.finally(() => {
					this.fetchPartnerAccounts()
				})
			},
			allowOutsideClick: () => !Swal.isLoading()
		}).then((result) => {
			if (result.isConfirmed) {
			  this.$swal({
				icon: 'success',
				title: 'Success',
				text: 'Account was successfully assigned',
				showCloseButton: true
			  })
			}
		})
	},
	async removeAccount (obj) {
		await this.$swal({
			icon: 'question',
			title: 'Please Confirm',
			text: 'Are you sure you want to delete this account?',
			showConfirmButton: true,
			showCancelButton: true,
			preConfirm: () => {
			  return this.axios
				.delete(
				  `/cost-revenue/v1/settlement-accounts/${obj.id}`
				)
				.catch((error) => {
				  const msg =
					error.response && error.response.data
					  ? error.response.data.message
					  : 'An error occured, please try again.'
	
				  this.$swal().showValidationMessage(msg)
				})
				.finally(() => {
					this.fetchPartnerAccounts()
				})
			},
			allowOutsideClick: () => !Swal.isLoading()
		}).then((result) => {
			if (result.isConfirmed) {
			  this.$swal({
				icon: 'success',
				title: 'Success',
				text: 'Account was successfully deleted',
				showCloseButton: true
			  })
			}
		})
	},
	openAccountModal () {
		this.$refs.accountModal.show()
	},
	closeAccountModal () {
		this.$refs.accountModal.hide()
		this.accName = ''
		this.accNumber = ''
		this.bankObj = {}
	}
  }
}
</script>

<style lang="scss" scoped>
@use 'src/assets/scss/b-table';
</style>
